import axios from "axios";
import { getRandomStr, getSign } from "./sign";
import { toast } from "react-toastify";
import i18n from "./i18n";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8000",
});

http.interceptors.request.use((config) => {
  let timestamp = new Date().getTime().toString().substring(0, 10);
  let nonce = getRandomStr(8);
  let data;
  if (config.method === "get" || config.method === "delete") {
    data = config.params;
  } else {
    data = config.data;
  }
  config.headers = config.headers ?? {};
  config.headers["x-timestamp"] = timestamp;
  config.headers["x-nonce"] = nonce;
  config.headers["x-sign"] = getSign(data, timestamp, nonce);
  config.headers["Accept-Language"] = i18n.language;
  return config;
});
http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let response = error.response;
    if (response.status < 500) {
      toast.error(response.data.error);
    } else {
      toast.error(i18n.t("error.server").toString());
    }
    return Promise.reject(error);
  }
);
export default http;
