import axios from "../axios";

async function getSearchTips(k: string) {
  if (k.trim() === "") {
    return [];
  }
  const response = await axios.get("/search/tips", {
    params: {
      k,
    },
  });
  return response.data;
}

async function getSearchResults(k: string, l: number, o: number, r: string, t: string) {
  const response = await axios.get("/search", {
    params: {
      k,
      l,
      o,
      r,
      t,
    },
  });
  return response.data;
}

export { getSearchTips, getSearchResults };
