import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getSearchTips } from "../../api/search";
import localforage from "localforage";
import { FiClock, FiSearch } from "react-icons/fi";
import { AiOutlineDelete, AiOutlineNotification } from "react-icons/ai";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { SearchProps } from "../../types/props";
import constants from "../../constants";
import SearchTitle from "./searchTitle";
import SearchIcon from "./searchIcon";
import { BsPeople, BsSearch } from "react-icons/bs";
import { BiBot } from "react-icons/bi";

function Search(props: SearchProps) {
  const [searchParams] = useSearchParams();
  const type_ = searchParams.get("t") ?? "all";
  const sort_ = searchParams.get("r") ?? "newest";
  const keyword = searchParams.get("k") ?? searchParams.get("keyword") ?? "";
  const [input, setInput] = useState(keyword);
  const [type, setType] = useState(type_);
  const [sort, setSort] = useState(sort_);
  const [tips, setTips] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [isBlur, setIsBlur] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      let data: Set<string> =
        (await localforage.getItem(constants.KEYWORDS)) ?? new Set();
      // @ts-ignore
      setKeywords([...data]);
      // @ts-ignore
      setTips(await getSearchTips(keyword));
      setInput(keyword);
    })();
  }, [keyword]);
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    let data = await getSearchTips(e.target.value);
    setTips(data);
  };
  const removeKeyword = async (keyword: string) => {
    let keywords: Set<string> =
      (await localforage.getItem(constants.KEYWORDS)) ?? new Set();
    keywords.delete(keyword);
    // @ts-ignore
    setKeywords([...keywords]);
    await localforage.setItem(constants.KEYWORDS, keywords);
  };
  const search = async (keyword: string) => {
    if (keyword.trim() === "") {
      toast.error(t("error.no_keyword"));
      return;
    }
    let keywords: Set<string> =
      (await localforage.getItem(constants.KEYWORDS)) || new Set();
    keywords.add(keyword);
    if (keywords.size > 10) {
      keywords.delete(keywords.values().next().value);
    }
    await localforage.setItem(constants.KEYWORDS, keywords);
    props.onSearch(keyword, sort, type, false);
  };
  const onSelectType = (type: string) => {
    setType(type);
    if (input.trim() !== "") {
      props.onSearch(input, sort, type, true);
    }
  };
  const onSelectSort = (sort: string) => {
    setSort(sort);
    if (input.trim() !== "") {
      props.onSearch(input, sort, type, true);
    }
  };
  return (
    <div className={"relative " + props.className ?? ""}>
      <SearchTitle className="mb-4" onClick={props.onClick} />
      <div className="tab tabs-boxed mb-4 flex justify-center">
        <label
          className={"tab gap-1" + (type === "all" ? " tab-active" : "")}
          onClick={() => onSelectType("all")}
        >
          <BsSearch size="1.2em" />
          {t("all")}
        </label>
        <label
          className={"tab gap-1" + (type === "group" ? " tab-active" : "")}
          onClick={() => onSelectType("group")}
        >
          <BsPeople size="1.2em" />
          {t("group")}
        </label>
        <label
          className={"tab gap-1" + (type === "channel" ? " tab-active" : "")}
          onClick={() => onSelectType("channel")}
        >
          <AiOutlineNotification size="1.2em" />
          {t("channel")}
        </label>
        <label
          className={"tab gap-1" + (type === "bot" ? " tab-active" : "")}
          onClick={() => onSelectType("bot")}
        >
          <BiBot size="1.2em" />
          {t("bot")}
        </label>
      </div>
      <div className="form-control w-full rounded-lg shadow-lg">
        <div className="flex">
          <input
            type="text"
            placeholder={t("search.placeholder")}
            value={input}
            onChange={async (e) => {
              setIsBlur(false);
              await onChange(e);
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                setIsBlur(true);
                await search(input);
              }
            }}
            className="input input-bordered w-full rounded-r-none placeholder:font-asap focus:outline-none"
            onFocus={() => setIsBlur(false)}
            onBlur={() => setIsBlur(true)}
          />
          <button
            className="btn btn-square rounded-l-none"
            onClick={async () => await search(input)}
          >
            <SearchIcon />
          </button>
        </div>
      </div>
      {!isBlur && (
        <div className="absolute z-10 w-full pt-2 shadow-xl lg:w-[50%]">
          <ul tabIndex={0} className="menu rounded bg-base-100 shadow">
            {(input ? tips : keywords).map((keyword) => (
              <li key={keyword}>
                <div
                  onMouseDown={async () => {
                    setInput(keyword);
                    await search(keyword);
                  }}
                  className="w-full"
                >
                  <div>{input ? <FiSearch /> : <FiClock />}</div>
                  <div className="truncate">{keyword}</div>
                  {!input && (
                    <div
                      className="ml-auto"
                      onMouseDown={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await removeKeyword(keyword);
                      }}
                    >
                      <AiOutlineDelete />{" "}
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {props.total !== undefined && (
        <div className="my-2 flex justify-between">
          <div className="text-start my-2 flex flex-col gap-2 text-sm text-gray-500 lg:flex-row lg:items-center justify-center">
            <span
              dangerouslySetInnerHTML={{
                __html: t("search.result", {
                  total: props.total,
                  time: props.time,
                }),
              }}
            ></span>
          </div>
          <div className="btn-group">
            <label 
              className={"btn font-asap pl-3 pr-3" + (sort === "newest" ? " btn-active" : "")} 
              onClick={() => onSelectSort("newest")}>
              {t("newest")}
            </label>
            <label 
              className={"btn font-asap pl-3 pr-3" + (sort === "number" ? " btn-active" : "")} 
              onClick={() => onSelectSort("number")}>
              {t("number")}
            </label>
            <label 
              className={"btn font-asap pl-3 pr-3 " + (sort === "heat" ? " btn-active" : "")} 
              onClick={() => onSelectSort("heat")}>
              {t("heat")}
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default Search;
