import { AdSiteProps } from "../types/props";
import { useTranslation } from "react-i18next";

export default function AdSite(props: AdSiteProps) {
  const { t } = useTranslation();

  return (
    <a href={props.link} target="_blank" rel="noreferrer">
      <div className="relative flex w-full flex-col justify-center rounded bg-base-100 p-3 shadow-xl">
        <span className="absolute text-[12px] right-[5px] top-[5px] text-gray-600">{t("ad")}</span>
        <div className="flex flex-row items-center space-x-2">
          <div className="avatar">
            <div className="h-20 w-20">
              <img src={props.image} alt={props.title} />
            </div>
          </div>
          <div className="truncate">
            <div className="font-bold">{props.title}</div>
            <div className="text-sm text-gray-500">{props.link}</div>
            <div className="text-sm text-gray-500">{props.description}</div>
          </div>
        </div>
      </div>
    </a>
  );
}
