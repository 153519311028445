import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useReducer, useState } from "react";
import { getSearchResults } from "../api/search";
import SearchComponent from "../components/search/search";
import { SearchResult } from "../types/response";
import Channel from "../components/channel";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MdExpandMore } from "react-icons/md";
import { BiArrowToTop } from "react-icons/bi";

function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [changeType, setChangeType] = useState(false);
  const [result, dispatch] = useReducer(
    (data: SearchResult, new_data: SearchResult) => {
      if (isMobile && !changeType) {
        new_data.data = [...data.data, ...new_data.data];
      }
      setChangeType(false);
      return new_data;
    },
    {
      data: [],
      total: 0,
      time_ms: 0,
    }
  );
  const navigate = useNavigate();
  const limit = isMobile ? 10 : 20;
  const [offset, setOffset] = useState(Number(searchParams.get("o") ?? 0));
  const [keyword, setKeyword] = useState(
    searchParams.get("k") ?? searchParams.get("keyword") ?? ""
  );
  const [type, setType] = useState(searchParams.get("t") ?? "all");
  const [sort, setSort] = useState(searchParams.get("r") ?? "newest");
  const [loading, setLoading] = useState(false);
  const [canPrev, setCanPrev] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await getSearchResults(keyword, limit, offset, sort, type);
      dispatch(data);
      setLoading(false);
      setCanPrev(offset > 0);
      setCanNext(offset + limit < data.total);
    })();
  }, [limit, offset, sort, type, keyword]);
  const prevPage = () => {
    if (canPrev) {
      setSearchParams({ k: keyword, r: sort, t: type, o: `${offset - limit}` });
      setOffset(offset - limit);
    }
  };
  const nextPage = () => {
    if (canNext) {
      setSearchParams({ k: keyword, r: sort, t: type, o: `${offset + limit}` });
      setOffset(offset + limit);
    }
  };
  return (
    <div className="px-[4%] lg:px-0">
      <SearchComponent
        className="lg:px-[25%]"
        onClick={() => navigate(`/`)}
        onSearch={(k, r, t, c) => {
          setChangeType(c);
          setSearchParams({ k, r, t, o: "0" });
          setKeyword(k);
          setSort(r);
          setType(t);
          setOffset(0);
        }}
        total={result.total}
        time={result.time_ms}
      />
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
        {result.data.map((c) => (
          <Channel channel={c} size={16} key={c.id} />
        ))}
      </div>
      <div className="flex justify-center py-4 lg:py-8">
        {isMobile ? (
          <>
            <button
              className={
                "btn btn-wide mr-2 grow gap-4" +
                (loading ? " loading" : "") +
                (canNext ? "" : " btn-disabled")
              }
              onClick={() => {
                nextPage();
              }}
            >
              <MdExpandMore size="1.5em" />
              {t("load_more")}
            </button>
            <button
              className="btn btn-square"
              onClick={() => window.scrollTo(0, 0)}
            >
              <BiArrowToTop size="1.5em" />
            </button>
          </>
        ) : (
          <div className="btn-group">
            <button
              className="btn"
              disabled={!canPrev}
              onClick={() => {
                prevPage();
              }}
            >
              {t("previous")}
            </button>
            <button
              className="btn"
              disabled={!canNext}
              onClick={() => {
                nextPage();
              }}
            >
              {t("next")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Search;
