import { FooterProps } from "../types/props";
import { BiLinkExternal } from "react-icons/bi";
import { useTranslation } from "react-i18next";

function Footer(props: FooterProps) {
  const { t } = useTranslation();
  return (
    <footer
      className={
        "footer footer-center bg-base-300 p-4 text-base-content " +
        props.className
      }
    >
      <div className="flex flex-col lg:flex-row">
        <div>
          <div>
            Copyright © 2022 - All right reserved by{" "}
            <a
              className="link font-bold"
              href="https://search.htloli.com"
              target="_blank"
              rel="noreferrer"
            >
              HtSearch
              <BiLinkExternal className="ml-1 inline-block" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
