import { useEffect } from "react";
import Index from "./views";
import { Routes, Route } from "react-router-dom";
import Search from "./views/search";
import Footer from "./components/footer";
import NotFound from "./components/404";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language === "zh-CN") {
      document.title = "HtSearch | TG搜索 | 电报搜索 | Telegram 搜索";
    } else {
      document.title = "HtSearch | TG Search | Search Telegram";
    }
  }, [i18n.language]);

  return (
    <div className="flex min-h-screen flex-col">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        pauseOnFocusLoss={false}
      />
      <Header />
      <div className="container mx-auto pt-4 lg:pt-8">
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="search" element={<Search />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer className="mt-auto" />
    </div>
  );
}

export default App;
