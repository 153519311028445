import { IoLanguage, IoMoonOutline, IoSunnyOutline } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import { themeChange } from "theme-change";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChannelInfo } from "../types/response";
import { toast } from "react-toastify";
import { addChannelInfo } from "../api/channel";
import { VscCloudUpload } from "react-icons/vsc";

export default function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const changeLanguage = async (lang: string) => {
    await i18n.changeLanguage(lang);
  };
  const [name, setName] = useState("");
  const [info, setInfo] = useState<ChannelInfo | null>(null);
  const [loading, setLoading] = useState(false);

  const addTg = async () => {
    setLoading(true);
    if (name === "") {
      toast.error(t("error.no_channel_group_name"));
      setLoading(false);
      return;
    }
    let info = await addChannelInfo(name).finally(() => {
      setLoading(false);
    });
    setInfo(info);
    toast.success(t("success.add_channel_group", { link: info.link }));
  };
  const clickChangeLanguage = async (
    e: React.MouseEvent<HTMLButtonElement>,
    language: string
  ) => {
    await changeLanguage(language);
  };
  useEffect(() => {
    themeChange(false);
  }, []);
  return (
    <div>
      <div className="navbar bg-neutral text-neutral-content">
        <div className="flex-1">
          <button
            className="btn btn-ghost flex gap-2 text-xl normal-case"
            onClick={() => navigate("/")}
          >
            <span className="font-asap">HtSearch</span>
          </button>
        </div>
        <div className="flex-none">
          <ul className="menu menu-horizontal p-0">
            <li tabIndex={0}>
              <button className="btn btn-ghost">
                <IoLanguage size="1.5em" />
              </button>
              <ul
                className="bg-neutral p-2 text-neutral-content"
                style={{
                  zIndex: 1,
                }}
              >
                <li>
                  <button
                    className="btn btn-ghost"
                    onClick={async (e) => await clickChangeLanguage(e, "zh-CN")}
                  >
                    简体中文
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-ghost"
                    onClick={async (e) => await clickChangeLanguage(e, "en-US")}
                  >
                    English
                  </button>
                </li>
              </ul>
            </li>
            <li>
              <button className="btn btn-ghost">
                <label className="swap-rotate swap">
                  <input type="checkbox" data-toggle-theme="light,dark" />
                  <IoSunnyOutline size="1.5em" className="swap-off" />
                  <IoMoonOutline size="1.5em" className="swap-on" />
                </label>
              </button>
            </li>
            <li>
              <label className="btn btn-ghost" htmlFor="submit" title={t("add")}>
                <VscCloudUpload size="1.5em" />
              </label>
            </li>
          </ul>
        </div>
      </div>
      <input type="checkbox" id="submit" className="modal-toggle" />
      <label htmlFor="submit" className="modal cursor-pointer">
        <label className="modal-box relative" htmlFor="submit">
          <div>
            <label
              htmlFor="tg-name"
              className="block text-center font-bold text-gray-400"
            >
              {t("submit_title")}
            </label>
            <div className="form-control mt-2">
              <label className="input-group font-asap">
                <span>https://t.me/</span>
                <input
                  id="tg-name"
                  type="text"
                  placeholder="htsearch"
                  className="input input-bordered w-full"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
                <button
                  className={"btn" + (loading ? " loading" : "")}
                  onClick={async () => await addTg()}
                >
                  {t("submit")}
                </button>
              </label>
            </div>

            {info && (
              <div
                className="flex flex-col justify-center rounded bg-base-100 p-5 shadow-xl hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  let items = info.link.split("/");
                  let link = "tg://resolve?domain=" + items[items.length - 1];
                  window.open(link);
                }}
              >
                <div className="flex flex-row items-center space-x-2">
                  <div className="avatar">
                    <div className="h-16 w-16 rounded-md">
                      <img src={info.image} alt={info.title} />
                    </div>
                  </div>
                  <div className="flex flex-col items-start">
                    <div className="break-all font-bold">{info.title}</div>
                    <div className="text-sm text-gray-500">
                      {info.count} members
                    </div>
                    <div className="break-words text-left text-sm text-gray-500">
                      {info.description}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </label>
      </label>
    </div>
  );
}
