import { HeadingProps } from "../../types/props";

function SearchTitle(props: HeadingProps) {
  return (
    <h1 className={"text-5xl font-bold " + props.className}>
      <div className="flex items-center justify-center space-x-4">
        <img src="/logo.png" alt="logo" width={60} height={60} />
        <div className="font-asap hover:cursor-pointer" onClick={props.onClick}>
          HtSearch
        </div>
      </div>
    </h1>
  );
}

export default SearchTitle;
