import { isSafari } from "react-device-detect";
import React from "react";
import { ChannelProps } from "../types/props";
import { ChannelItem, ChannelType, LinkResult } from "../types/response";
import { getLink } from "../api/link";
import { useTranslation } from "react-i18next";
import { addOpenChannelCount } from "../api/channel";

function icon(type: ChannelType) {
  switch (type) {
    case "channel":
      return "📢";
    case "group":
      return "👥";
    case "bot":
      return "🤖";
    default:
      return "❓";
  }
}

function numFormatter(num: number) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K";
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num <= 999) {
    return num;
  }
}

export default function Channel(props: ChannelProps) {
  const item = props.channel;
  const size = props.size;
  const openLink = async (
    e: React.MouseEvent<HTMLLabelElement>,
    item: ChannelItem,
    type: string
  ) => {
    let link = item.link;
    if (!link) {
      let ret: LinkResult = await getLink(item.id);
      link = ret.link;
    }
    let items = link.split("/");
    let domain = items[items.length - 1];
    switch (type) {
      case "preview":
        link = link.replace("https://t.me/", "https://t.me/s/");
        break;
      case "app":
        link = "tg://resolve?domain=" + domain;
        break;
      case "web":
        link = "https://web.telegram.org/k/#@" + domain;
    }
    await addOpenChannelCount(item.id);
    if (isSafari && type === "app") {
      window.location.href = link;
    } else {
      window.open(link, "_blank");
    }
  };
  const { t } = useTranslation();
  return (
    <label
      htmlFor={"modal-" + item.id}
      className="modal-button flex place-content-center hover:cursor-pointer"
      key={item.id}
    >
      <input type="checkbox" id={"modal-" + item.id} className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <div className="flex flex-row space-x-2">
            <div className="w-full">
              <div className="font-bold">
                <span
                  dangerouslySetInnerHTML={{
                    __html: icon(item.type) + " " + item.title,
                  }}
                ></span>
              </div>
              <div className="text-sm text-gray-500">
                {numFormatter(item.count)} {t("members")} |{" "}
                {item.updated_at.split(" ")[0]} | {item.language} | 🔥{" "}
                {numFormatter(item.open_count)}
              </div>
              <div className="mt-2 flex w-full gap-2">
                <div className="avatar">
                  <div className="h-20 w-20 rounded-md">
                    <img src={item.image} alt={item.title} />
                  </div>
                </div>
                <div className="flex-1 text-sm text-gray-500">
                  <label 
                    className="btn w-full h-full whitespace-pre-line pt-3 pb-3 pl-3 pr-3 text-left leading-5 block" 
                    dangerouslySetInnerHTML={{ __html: item.description }} 
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 flex w-full items-center gap-2">
            <div className="flex-1 text-sm text-gray-500">
              <label className="btn w-full">
                {t("disclaimer")}
              </label>
            </div>
          </div>
          <div className="btn-group modal-action mt-2">
            <label
              className="btn font-asap"
              htmlFor={"modal-" + item.id}
              onClick={async (e) => await openLink(e, item, "preview")}
            >
              {t("preview")}
            </label>
            <label
              className="btn font-asap"
              htmlFor={"modal-" + item.id}
              onClick={async (e) => await openLink(e, item, "web")}
            >
              {t("jump_web")}
            </label>
            <label
              className="btn font-asap"
              htmlFor={"modal-" + item.id}
              onClick={async (e) => await openLink(e, item, "app")}
            >
              {t("jump_app")}
            </label>
          </div>
        </div>
      </div>
      <div className="relative flex w-full flex-col justify-center rounded bg-base-100 p-3 shadow-xl">
        <div className="flex flex-row items-center space-x-2">
          <div className="avatar">
            <div className={`w-${size} h-${size} rounded-md`}>
              <img src={item.image} alt={item.title} />
            </div>
          </div>
          <div className="truncate">
            <div className="font-bold">
              <span
                dangerouslySetInnerHTML={{
                  __html: icon(item.type) + " " + item.title,
                }}
              />
            </div>
            <div className="text-sm text-gray-500">
              {numFormatter(item.count)} {t("members")} |{" "}
              {item.updated_at.split(" ")[0]} | {item.language} | 🔥{" "}
              {numFormatter(item.open_count)}
            </div>
            <div className="text-sm text-gray-500">
              <span 
                dangerouslySetInnerHTML={{ __html: item.description }} 
              />
            </div>
          </div>
        </div>
      </div>
    </label>
  );
}
