import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Search from "../components/search/search";
import { useTranslation } from "react-i18next";
import { getAdChannels } from "../api/channel";
import Channel from "../components/channel";
import { AdSiteItem, ChannelItem } from "../types/response";
import axios from "axios";
import AdSite from "../components/adSite";
import { getAdSites } from "../api/adsite";

function Index() {
  const [adChannels, setAdChannels] = useState<Array<ChannelItem>>([]);
  const [adSites, setAdSites] = useState<Array<AdSiteItem>>([]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const getAdChannels_ = async () => {
    let data = await getAdChannels();
    setAdChannels(data);
  };
  const getAdSites_ = async () => {
    let data = await getAdSites();
    setAdSites(data);
  };
  const loadPageData = useCallback(async () => {
    await axios.all([getAdChannels_(), getAdSites_()]);
  }, []);
  useEffect(() => {
    (async () => {
      await getAdChannels_();
    })();
  }, [i18n.language]);
  useEffect(() => {
    (async () => {
      await loadPageData();
    })();
  }, [i18n.language, loadPageData]);

  return (
    <div className="mb-4 px-[4%] pt-[10%] lg:px-0">
      <Search
        className="mb-4 lg:px-[25%]"
        onClick={async () => {
          await loadPageData();
        }}
        onSearch={(k, r, t, c) => {
          if (!c) {
            navigate(`/search?k=${k}&r=${r}&t=${t}`);
          }
        }}
      />
      <div className="flex justify-center text-gray-400">
        {t("index_search_tip")}
      </div>
      <div className="divider"></div>
      <div className="flex flex-col gap-2">
        {adChannels.length > 0 && (
          <div className="flex flex-col gap-2">
            <div className="flex flex-wrap gap-2">
              <div className="grid min-w-full grid-cols-1 gap-4 border-gray-50 lg:grid-cols-3">
                {adChannels?.map((c) => (
                  <Channel channel={c} key={c.id} size={20} />
                ))}
                {adSites?.map((c) => (
                  <AdSite
                    key={c.id}
                    link={c.link}
                    image={c.image}
                    title={c.title}
                    description={c.description}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;
