import axios from "../axios";
import { ChannelInfo, ChannelItem } from "../types/response";

async function addChannelInfo(name: string): Promise<ChannelInfo> {
  const response = await axios.post(`/channel/${name}`);
  return response.data;
}

async function getAdChannels(): Promise<Array<ChannelItem>> {
  const response = await axios.get(`/channel/ads`);
  return response.data;
}

async function addOpenChannelCount(id: number) {
  const response = await axios.post(`/channel/${id}/count`);
  return response.data;
}

export { addChannelInfo, addOpenChannelCount, getAdChannels };
